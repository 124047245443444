<template>
    <auth-layout successSentEmail>
      <!-- /.login-card-body -->
    </auth-layout>
  </template>
  
  <script>
  const { console } = window;
  import AuthLayout from "@components/layouts/Auth.vue";
  
  export default {
    components: {
      AuthLayout,
    },
    data: () => ({
      prevRoute: {
        name: "login",
      },
      form: {
        username: null,
      },
      formreset: {
        otp: null,
        password: null,
      },
      error: undefined,
      errorRequest: undefined,
      loading: false,
    }),
    mounted() {
      $("body").attr("class", "hold-transition vm-login-page");
      $("body,html").removeAttr("style");
      $("body").Layout("fixLayoutHeight");
    },
    methods: {
    //   submit() {
    //     this.loading = true;
    //     this.Api.forgot("/forgot_password/request", this.form)
    //       .then((res) => {
    //         this.loading = false;
    //         alert("Tolong cek email anda!");
    //         // $("#myModal1").modal("show");
    //       })
    //       .catch((err) => {
    //         this.loading = false;
    //         this.errorRequest = err.response.data.message;
    //       });
    //   },
    //   reset() {
    //     this.loading = true;
    //     this.Api.reset("/forgot_password/reset", this.formreset)
    //       .then((res) => {
    //         this.loading = false;
    //         alert("Reset password success");
    //         $("#myModal1").modal("hide");
    //         this.$router.push(this.prevRoute);
    //       })
    //       .catch((err) => {
    //         this.loading = false;
    //         this.error = err.response.data.message;
    //       });
    //   },
    },
  };
  </script>
  
  <style>
  .login-banner {
    width: 100px;
    margin: auto;
    display: block;
  }
  .login-page {
    background: #333333 !important;
  }
  .login-page .login-logo {
    padding-top: 20px;
    margin-bottom: 0px;
  }
  .login-page .login-logo img {
    background-position: center center;
    width: 26px;
    height: 26px;
    margin-top: -6px;
    margin-right: 6px;
  }
  </style>
  